class Utils {
    static isDev () {
        return process.env.NODE_ENV === 'development'
    }

    static isProd () {
        return process.env.NODE_ENV === 'production'
    }

    static getEnvironment () {
        return process.env.NODE_ENV
    }

    static shouldAllowUnpublishedPosts () {
        return Environment.isDev()
    }
}

module.exports = Utils
