import React, {Component} from 'react'
import {Link} from 'gatsby'
import Browser from 'bowser'
import uuidv4 from 'uuid/v4'

import {WebCookieConsent} from '@epi/epi-ui'

import Telemetry from '../lib/Telemetry'
import Cookies from '../lib/Cookies'

const getFriendlyOsName = (osName) => {
    if (osName === 'macOS') {
        return 'Mac'
    }
    return osName
}

class App extends Component {

    constructor (props) {
        super(props)

        this.state = {
            os: null,
            platform: {},
            telemetry: {},
            cookieConsentState: null
        }
    }

    async componentDidMount () {
        Cookies.init()
        this.retrieveStoredCookieConsentState()

        const journey = this.getJourneyId()
        const visit = this.generateVisitId()

        const device = Browser.parse(window.navigator.userAgent)

        if (device && device.browser && device.browser.name) {
            document.body.dataset.browserName = device.browser.name.toLowerCase()
        }

        let ip = {}
        let geoip = {}

        try {
            const geoipResponse = await fetch(process.env.EPI_GEOIP_URL)

            const geoipResponseData = await geoipResponse.json()
            ip = geoipResponseData.ip
            geoip = geoipResponseData.geoip
        } catch (e) {
        }

        let userLocale = null
        let userTimezone = null

        try {
            const intl = Intl.DateTimeFormat().resolvedOptions()
            userLocale = intl.locale
            userTimezone = intl.timeZone
        } catch (e) {
        }

        this.setState({
            os: getFriendlyOsName(device.os.name),
            device,
            telemetry: {
                journey,
                visit,
                ip: ip,
                geoipCityName: geoip.cityName,
                geoipCountryCode: geoip.countryCode,
                geoipCountryName: geoip.countryName,
                geoipLocation: geoip.location,
                geoipLocationAccuracyRadius: geoip.locationAccuracyRadius,
                osType: device.platform.type,
                osVendor: device.platform.vendor,
                osPlatform: null,
                osPlatformVersion: null,
                osFamily: device.os.name,
                osName: null,
                osVersion: device.os.version,
                browserName: device.browser.name,
                browserVersion: device.browser.version,
                userAgent: window.navigator.userAgent,
                userLanguage: window.navigator.userLanguage || window.navigator.language,
                userLocale: userLocale,
                userTimezone: userTimezone,
                browserOuterWidth: window.outerWidth,
                browserInnerWidth: window.innerWidth,
                browserOuterHeight: window.outerHeight,
                browserInnerHeight: window.innerHeight,
            }
        }, async () => {
            await Telemetry.init(this.state.telemetry)

            Telemetry.addPageView({
                uri: window.location.href,
                referrer: document.referrer
            })
        })
    }

    async componentDidUpdate (prevProps, prevState) {
        if (Telemetry.initialised) {
            Telemetry.addPageView({
                uri: window.location.href,
                referrer: document.referrer
            })
        }
    }

    retrieveStoredCookieConsentState () {
        const storedCookieConsentState = Cookies.getCookieConsentState()

        if (!storedCookieConsentState || (storedCookieConsentState !== App.COOKIE_CONSENT_STATE_ACCEPTED && storedCookieConsentState !== App.COOKIE_CONSENT_STATE_DECLINED)) {
            this.setState({
                cookieConsentState: App.COOKIE_CONSENT_STATE_REQUIRED
            })
            return
        }

        this.setState({
            cookieConsentState: storedCookieConsentState
        })
    }

    getJourneyId () {
        const cookiedJourneyId = Cookies.getJourneyId()

        if (!cookiedJourneyId) {
            return uuidv4()
        }

        return cookiedJourneyId
    }

    generateVisitId () {
        return uuidv4()
    }

    handleCookieConsentAccept = () => {
        this.setState({
            cookieConsentState: App.COOKIE_CONSENT_STATE_ACCEPTED
        }, () => {
            Cookies.setCookieConsentState(this.state.cookieConsentState)
            Cookies.setJourneyId(this.state.telemetry.journey)
        })
    }

    handleCookieConsentDecline = () => {
        this.setState({
            cookieConsentState: App.COOKIE_CONSENT_STATE_DECLINED
        }, () => {
            Cookies.setCookieConsentState(this.state.cookieConsentState)
        })
    }

    shouldCookieConsentBeDisplayed () {
        return this.state.cookieConsentState === App.COOKIE_CONSENT_STATE_REQUIRED
    }

    getChildren (props) {
        return React.Children.map(this.props.children, (child, index) => {
            return React.cloneElement(child, {
                ...props,
                ...this.state
            })
        })
    }

    render () {
        return (
            <div className="App">
                {this.getChildren(this.props)}
                <div className="App-cookie-consent">
                    <WebCookieConsent organisationName="Epiarc.org" heading="Cookies" cookiePolicyLink={<Link to="/legal/cookies">cookie policy</Link>} onAccept={this.handleCookieConsentAccept} onDecline={this.handleCookieConsentDecline} display={this.shouldCookieConsentBeDisplayed()} />
                </div>
            </div>
        )
    }
}

App.COOKIE_CONSENT_STATE_REQUIRED = 'required'
App.COOKIE_CONSENT_STATE_ACCEPTED = 'accepted'
App.COOKIE_CONSENT_STATE_DECLINED = 'declined'

export default App
