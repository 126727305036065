// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("/Users/ajrg/workspace/epiarc-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("/Users/ajrg/workspace/epiarc-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-concepts-js": () => import("/Users/ajrg/workspace/epiarc-website/src/pages/concepts.js" /* webpackChunkName: "component---src-pages-concepts-js" */),
  "component---src-pages-contact-js": () => import("/Users/ajrg/workspace/epiarc-website/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-legal-cookies-js": () => import("/Users/ajrg/workspace/epiarc-website/src/pages/legal/cookies.js" /* webpackChunkName: "component---src-pages-legal-cookies-js" */),
  "component---src-pages-legal-privacy-js": () => import("/Users/ajrg/workspace/epiarc-website/src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-suppliers-js": () => import("/Users/ajrg/workspace/epiarc-website/src/pages/legal/suppliers.js" /* webpackChunkName: "component---src-pages-legal-suppliers-js" */),
  "component---src-pages-legal-terms-js": () => import("/Users/ajrg/workspace/epiarc-website/src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-paper-js": () => import("/Users/ajrg/workspace/epiarc-website/src/pages/paper.js" /* webpackChunkName: "component---src-pages-paper-js" */),
  "component---src-pages-rfc-js": () => import("/Users/ajrg/workspace/epiarc-website/src/pages/rfc.js" /* webpackChunkName: "component---src-pages-rfc-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/ajrg/workspace/epiarc-website/.cache/data.json")

